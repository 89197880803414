"use client";

import { captureException } from "@sentry/nextjs";
import { useEffect, useState } from "react";

import { ErrorPage } from "../components/ErrorPage/ErrorPage";
import { Header } from "../components/Header/Header";
import { getUserStatus } from "../utils/auth/getUserStatus";

export default function GlobalError({ error }: { error: Error }) {
  const [userStatus, setUserStatus] = useState({
    isPremium: false,
    isLoggedIn: false,
    shouldLogout: false,
  });

  useEffect(() => {
    captureException("Layout or similar crashed: " + error);

    const fetchUserStatus = async () => {
      const { isPremium, isLoggedIn, shouldLogout } = await getUserStatus();
      setUserStatus({ isPremium, isLoggedIn, shouldLogout });
    };

    fetchUserStatus();
  }, [error]);

  return (
    <html>
      <body>
        <Header
          isLoggedIn={userStatus.isLoggedIn}
          isPremium={userStatus.isPremium}
          shouldLogout={userStatus.shouldLogout}
        />
        <ErrorPage
          heading="Oj! Något har gått fel."
          paragraph="Vi ber om ursäkt och försöker åtgärda problemet så snart vi kan, håll
    ut! Har du frågor eller funderingar? Mejla oss på info@omni.se"
        />
      </body>
    </html>
  );
}
