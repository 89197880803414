import type { SpidSettings } from "../../types/settings";

import styles from "./Header.module.scss";
import { HeaderActions } from "./HeaderActions/HeaderActions";
import { getCategoryData } from "../../services/getCategoryData";
import { getSpidProfile } from "../../utils/auth/getSPIDProfile";
import { LogoutController } from "../../utils/auth/LogoutController";
import { Logo } from "./Menu/Logo/Logo";

const Header = async ({
  isLoggedIn,
  isPremium,
  shouldLogout,
}: {
  isLoggedIn: boolean;
  isPremium: boolean;
  shouldLogout: boolean;
}) => {
  let userSettings: SpidSettings | undefined;
  if (isLoggedIn) {
    userSettings = await getSpidProfile();
  }

  const categoryData = await getCategoryData({ withDividers: true });
  return (
    <header className={styles.headerContainer}>
      <LogoutController shouldLogout={shouldLogout} />
      <div className={styles.headerContent}>
        <Logo />
        <div className={styles.headerActionsContainer}>
          <HeaderActions
            categoryData={categoryData.data || undefined}
            isLoggedIn={isLoggedIn}
            isPremium={isPremium}
            userSettings={userSettings}
          />
        </div>
      </div>
    </header>
  );
};

export { Header };
