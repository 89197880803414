import type { CategoriesResponse } from "../types/content";
import type { FetchError } from "../types/fetchError";
import type { ErrorResponse, OkResponse } from "../types/serviceResponse";

const getCategoryData = async ({
  withDividers,
}: {
  withDividers: boolean;
}): Promise<OkResponse<CategoriesResponse> | ErrorResponse> => {
  const baseUrl = process.env.CONTENT_API_BASE_URL;
  const categoryURL = `${baseUrl}/categories${withDividers ? "?feature_flag=dividers" : ""}`;
  try {
    const res = await fetch(categoryURL, { next: { revalidate: 3600 } });

    if (!res.ok) {
      return {
        error: {
          status: res.status,
          statusText: res.statusText,
          message: `Failed to fetch article: ${res.status} ${res.statusText}`,
        } as FetchError,
        data: null,
      };
    }

    const categories: CategoriesResponse = await res.json();
    return { data: categories, error: null };
  } catch (e) {
    const error = e as Error;
    console.error(error);
    return {
      data: null,
      error: {
        message: error.message,
      } as FetchError,
    };
  }
};

export { getCategoryData };
